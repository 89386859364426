#roadmap .inner_roadmap {
    background-image: url("../public/img/road.png");
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: 85% auto;
    padding-top: 100px;
    margin-top: -100px;
}


.arrow__first {
    position: relative;
    top: 250px;
    left: -195px;
    /* transform: rotate(-11deg) !important; */
}
.arrow__second {
    position: relative;
    top: 310px;
    left: 10px;
    /* transform: rotate(3deg) !important; */
}
.arrow__third {
    position: relative;
    top: 350px;
    left: -190px;
    /* transform: rotate(-9deg) !important; */
}
.arrow__fouth {
    position: relative;
    top: 330px;
    left: 230px;
    /* transform: rotate(10deg) !important; */
}
.arrow__fiveth {
    position: relative;
    top: 270px;
    left: -195px;
    /* transform: rotate(-13deg) !important; */
}

.text-road-shadow {
    text-shadow: 0 0 20px rgb(156, 46, 135);
}

.padding_res {
    padding-left: 50px;
    padding-right: 50px;
}

.ques1_img1_mr {
    margin-left: -60px;
}

/* .ques1_arrow_mr {
    margin-left: -60px;
} */

.ques3 {
    /* margin-right: -100px; */
}


/* .control_row4 {
    margin-top: -150px;
} */


.control_row {
    margin-top: 60px
}


/* .control_row2 {
    margin-top: -70px
} */

/* .text_control {
    margin-top: -55px
} */

/* .control_row3 {
    margin-top: -280px
} */

/* .section__second-phase {
    margin-top: -200px;
} */

.trans {
    transform: rotate(-15deg);
    margin-top: 0px;
    font-size: 70px;

}


.first-txt {
    position: absolute;
    top: 80px;
    left: 80px;
    color: #391B0D;
    transform: rotate(-12deg);
    font-weight: 500;
    font-size: 18px;
}

.second-txt {
    position: absolute;
    top: 80px;
    right: 45px;
    color: #391B0D;
    transform: rotate(12deg);
    font-weight: 500;
    font-size: 18px;
}

.third-txt {
    position: absolute;
    top: 80px;
    right: 80px;
    color: #391B0D;
    transform: rotate(15deg);
    font-weight: 500;
    font-size: 18px;
}

.fivht-txt {
    position: absolute;
    top: 80px;
    left: 80px;
    color: #391B0D;
    transform: rotate(-10deg);
    font-weight: 500;
    font-size: 18px;
}


.ma {
    margin-top: 70px
}

.road__text {
    font-size: 1rem;
    line-height: 1.125rem;
    list-style: none;
    margin: 0;
    padding-left: 2rem;
}
.road__text li {
    position: relative;
    margin-top: 1.25rem;
}
.road__text li:before {
    content: '-';
    position: absolute;
    left: -1rem;
    top: 0;
    color: #D3EA4E;
    margin-right: 0.5rem;
}

.road__block {
    margin-top: -205px;
}
.road__block_secondy {
    margin-top: -100px;
}

.phase__four-descr {
    width: 70%;
}

/* @media only screen and (max-width: 600px) {
    .control_row3 {
        margin-top: 100px;
    }.control_row{
        margin-top: 100px
    }
    .control_row2{
        margin-top: 100px
    }
  } */




/* @media only screen and (max-width: 1200px) { */
    /* .control_row3 {
        margin-top: 0px;
        display: flex !important;
        justify-content: center !important;
    }

    .control_row {
        margin-top: 0px;
        display: flex !important;
        justify-content: center !important;
    }

    .control_row2 {
        margin-top: 0px;
        display: flex !important;
        justify-content: center !important;
    } */

    /* .control_row4 {
        margin-top: -230px;
        display: flex !important;
        justify-content: center !important;
    } */

    /* .second-txt {
        position: absolute;
        top: 80px;
        right: 80px;
        color: #391B0D;
        transform: rotate(10deg);
    } */

    /* #roadmap .inner_roadmap {
        background-image: none;
    }

    .phase__four-descr {
        width: 100%;
    } */
/* } */

/* @media only screen and (max-width: 1300px) { */
    /* .control_row3 {
        margin-top: 0px;
        display: flex !important;
        justify-content: center !important;
    }

    .control_row {
        margin-top: 0px;
        display: flex !important;
        justify-content: center !important;
    }

    .control_row2 {
        margin-top: 0px;
        display: flex !important;
        justify-content: center !important;
    } */

    /* .control_row4 {
        margin-top: 50px;
        display: flex !important;
        justify-content: center !important;
    } */

    /* .second-txt {
        position: absolute;
        top: 60px;
        right: 60px;
        color: #391B0D;
        transform: rotate(10deg);
    } */

    /* #roadmap .inner_roadmap {
        background-image: none !important;
    } */

    /* .trans {
        display: none;
    }

    .flex {
        display: flex !important;
        justify-content: center;
    } */
/* } */

.phase-5 {
    margin-left: -20px;
}


@media only screen and (max-width: 600px) {
    /* .control_row3 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .control_row {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 100px;
    } */

    /* .control_row4 {
        padding-left: 20px;
        padding-right: 20px;
        margin-top: -50px;
    } */

    /* .control_row2 {
        padding-left: 0px !important;
        padding-right: 0px !important;
        display: flex !important;
        justify-content: start !important;
    } */

    /* .ani {
        display: flex !important;
        justify-content: center !important;
    } */

    /* .res_center {
        margin-right: 100px !important;
        padding-bottom: 40px;
    }

    .reverse_res {
        display: flex !important;
        flex-direction: column-reverse !important;

    }

    .col_res {
        display: flex;
        justify-content: center;
    }

    .col_res_text {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .padding_res {
        padding-left: 20px !important;
        padding-right: 20px !important;
    } */

    /* .res_mr_ques3 {
        margin-top: 100px
    } */

    .back {
        background-image: url("../public/img/Rick-bg.png");
        background-size: 650px !important;

    }

    /* .img-slider-size{
        height:1000px !important;
        margin: auto 0;
    } */

    #mint--section {
        padding-left: 24px !important;
        padding-right: 25px !important;
    }
}

.back {
    background-image: url("../public/img/Rick-bg.png");
    background-size: 900px
}

.swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
}

.img-slider-size {
    height: 600px !important;
    margin: auto 0;
}


@media (min-width: 0) and (max-width: 992px) {
    #roadmap .inner_roadmap {
        background: url("../public/img/m-road.png") repeat-y 23px 0;
        background-size: 90px auto;
        padding-top: 0;
        margin-top: 0;
    }

    .road__text {
        padding-left: 1rem;
    }

    .arrow__first {
        top: 150px;
        left: 0;
    }

    .arrow__second {
        top: 150px;
        left: 0;
    }

    .arrow__third {
        top: 180px;
        left: 0;
    }

    .arrow__fouth {
        top: 230px;
        left: 0;
    }

    .arrow__fiveth {
        top: 150px;
        left: 0;
    }

    .first-txt {
        top: 41px;
        left: 52px;
        transform: rotate(12deg);
        font-size: 10px;
    }
    .second-txt {
        top: 41px;
        left: 52px;
        right: auto;
        transform: rotate(12deg);
        font-size: 10px;
    }
    .third-txt {
        top: 41px;
        left: 52px;
        right: auto;
        transform: rotate(12deg);
        font-size: 10px;
    }
    .fivht-txt {
        top: 41px;
        left: 52px;
        transform: rotate(12deg);
        font-size: 10px;
    }
}

@media (min-width: 0) and (max-width: 1279px) {
    #roadmap .inner_roadmap {
        padding-bottom: 0;
    }

    .road__block , .road__block_secondy {
        margin-top: 0;
    }
}

@media (min-width: 992px) and (max-width: 1279px) {
    #roadmap .inner_roadmap {
        background-size: 115% auto;
    }
    .arrow__first {
        top: 50px;
        left: -165px;
    }
    .arrow__second {
        top: 190px;
        left: -50px;
    }
    .arrow__third {
        top: 170px;
        left: -100px;
    }
    .arrow__fouth {
        top: 210px;
        left: 100px;
    }
    .arrow__fiveth {
        top: 100px;
        left: -135px;
    }

    .second-txt {
        top: 80px;
        right: -65px;
    }
    .third-txt {
        top: 80px;
        right: -50px;
    }
}

@media (min-width: 1280px) and (max-width: 1299px) {
    .arrow__first {
        top: 170px;
        left: -260px;
    }
    .arrow__second {
        top: 220px;
        left: -50px;
    }
    .arrow__third {
        top: 120px;
        left: -60px;
    }
    .arrow__fouth {
        top: 210px;
        left: 60px;
    }
    .arrow__fiveth {
        top: 150px;
        left: -140px;
    }

    .second-txt {
        right: -40px;
    }
    .third-txt {
        right: -25px;
    }
}

@media (min-width: 1440px) and (max-width: 1679px) {
    .arrow__first {
        top: 170px;
        left: -220px;
    }

    .arrow__second {
        top: 220px;
        left: 20px;
    }

    .arrow__third {
        top: 120px;
        left: -205px;
    }

    .arrow__fouth {
        top: 270px;
        left: 190px;
    }

    .arrow__fiveth {
        top: 190px;
        left: -210px;
    }

    .second-txt {
        right: 10px;
    }

    .third-txt {
        right: 40px;
    }
}

@media (min-width: 1920px) {
    .arrow__first {
        top: 170px;
        left: -145px;
    }

    .arrow__third {
        top: 290px;
        left: -250px;
    }

    .arrow__fiveth {
        left: -165px;
    }

    .second-txt {
        right: 80px;
    }

    .third-txt {
        right: 120px;
    }
}
